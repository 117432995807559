@import url("https://use.typekit.net/uor2kuf.css");
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

@font-face {
  font-family: 'Nevo';
  src: url('./assets/fonts/Regular/Nevo-Regular.woff2') format('woff2'), url('./assets/fonts/Regular/Nevo-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Nevosemibold';
  src: url('./assets/fonts/SemiBold/Nevo-SemiBold.woff2') format('woff2'), url('./assets/fonts/SemiBold/Nevo-SemiBold.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'FuturaPTMedium';
  src: url('./assets/fonts/Regular/FuturaPTMedium.otf') format('woff2'), url('./assets/fonts/Regular/FuturaPTMedium.otf') format('otf');
  font-weight: normal;
}

.validation-margin-top {
  margin-top: 50px;
}

.phone {
  color: white;
  text-decoration: none;
}

.payment-line {
  border-top: 1px solid #4B4F54;
  margin-top: 3%;
}

.payment-line-desktop {
  border-top: 1px solid #4B4F54;
  margin-top: 8%;
  margin-right: 30%;
}

/*todo: add different margins for different view sizes*/
.twico-container {
  margin: 0 100px;
}

html {
  scroll-behavior: smooth;
}

select {
  padding-left: 8px;
}

.row-med {
  height: 55px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  /*51 to avoid pixel fighting that causes borders to disapear at 110% zoom*/
  /*it only seemed to happen on the "enter address" box on the first page*/
  transform: translateY(-51%);
}

.vertical-center-relative {
  margin: 0;
  position: relative;
  top: 48%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-center-onoffswitch {
  margin: 0;
  position: relative;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.horizontal-center {
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.checkbox-label {
  margin-left: 30px;
}

.checkbox-label-information {
  font-family: futura-pt;
  font-size: 16pt;
  color: #4B4F54;
  margin-left: 10px;
  float: left;
  font-weight: 400;
}

.div-checkbox {
  margin-left: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  vertical-align: text-bottom;
}

.top-image-container {
  height: 305px;
  margin-bottom: 50px;
  position: sticky;
  top: -125px;
  z-index: 20;
}

.top-image {
  height: inherit;
  width: 100%;
  object-fit: cover;
}

.pointer {
  cursor: pointer;
}

.login {
  font-family: nevosemibold;
  font-size: 36px;
  color: #006580;
  cursor: pointer;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /*width: 50%;*/
}

.is-invalid { /*todo*/
  border-color: #BA0C2F !important;
}

.validation-error {
  color: #BA0C2F !important;
  font-family: futura-pt;
  font-size: 21px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.divider-line {
  height: 1px;
  background-color: #3EA26F;
  width: 100%;
}

.divider-line-drk-gry {
  height: 1px;
  background-color: #97999c;
  width: 100%;
}

.divider-line-lt-gry {
  height: 1px;
  background-color: #c4e9e9;
  width: 100%;
}

.title {
  text-align: left;
  font-family: nevosemibold;
  font-size: 28px;
  color: #4B4F54;
}

.sub-title {
  font-family: futura-pt;
  font-weight: 600;
  font-size: 30px;
  color: #004e59;
}

.header {
  text-align: left;
  font-family: nevo;
  color: #006580;
  font-size: 24px;
  font-weight: 500;
}

.sub-header {
  font-family: futura-pt;
  font-size: 20px;
  color: #4b4f54;
}

.label {
  text-align: left;
  font-family: 'FuturaPTMedium';
  font-weight: 500;
  font-size: 24px;
  color: #4B4F54;
}

.sub-label {
  font-family: futura-pt;
  font-size: 20px;
  color: #4B4F54;
}

.warning-message {
  color: #E87722 !important;
  font-family: futura-pt;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.warning-message-underlined {
  color: #E87722 !important;
  font-family: futura-pt;
  font-size: 21px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-decoration: underline;
  cursor: pointer;
}

.custom-href {
  font-family: futura-pt;
  font-size: 22px;
  color: #40A26F;
  text-decoration: underline;
  cursor: pointer;
}

.error-message {
  color: #BA0C2F;
  font-family: futura-pt;
  font-size: 20px;
}

.input {
  width: 300px;
  height: 30px;
}

.input-margin-right {
  margin-right: 30px;
}

.input-margin-left {
  margin-left: 30px;
}

.input-margin-top {
  margin-top: 10px;
}

.input-margin-bottom {
  margin-bottom: 10px;
}

.date-input {
  width: 240px;
}

.date-input-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  cursor: pointer;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

.information-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
  vertical-align: middle;
}

.home-date {
  display: inline;
}

.address-entry {
  display: inline;
}

.button {
  font-family: futura-pt;
  font-weight: bolder;
  font-size: 24px;
  color: white;
  background-color: #006580;
  width: 160px;
  height: 40px;
  border: 0;
  padding: 0;
}

.button-long {
  font-family: futura-pt;
  font-weight: bolder;
  font-size: 15px;
  color: white;
  background-color: #006580;
  width: auto;
  height: auto;
  border: 0;
  padding: 5px;
}

.long-button {
  width: 315px;
}

.button:disabled:hover {
  background-color: gray;
}

.button:disabled {
  background-color: gray;
}

.button:hover {
  background-color: #e87722;
}

.section-divider {
  height: 30px;
}

.button-300px-wide:hover {
  background-color: #e87722;
}

.button-250px-wide {
  font-family: futura-pt;
  font-weight: bolder;
  font-size: 34px;
  color: white;
  background-color: #006580;
  width: 250px;
  height: 55px;
  border: 0;
}

  .button-250px-wide:hover {
    background-color: #e87722;
  }


.button-white {
  background-color: white;
  color: #006580;
  border: 2px solid #006580;
}

.float-right {
  float: right !important;
  margin-left: auto;
}

.swal2-shown {
  display: flex;
  height: 100vh;
}

img.loading {
  /*z-index: 100;*/
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
}

.overlay {
  z-index: 10002;
  background-color: rgba(0,0,0,0.2); /* Black background with opacity */
  position: fixed;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.agency-selector {
  width: 200px;
}

.twico-modal-body {
  margin: 0 45px;
}

.twico-modal-body-choosepackage {
  position: relative;
  overflow-y: auto;
  max-height: 400px;
  padding: 15px;
}

.twico-modal-text {
  font-family: futura-pt;
  font-size: 20px;
}

.modal-content {
  border-radius: 0 !important;
}

.modal-dialog {
  /*  margin-top: 270px !important;*/
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  /*width: 55% !important;*/
  /*max-width: 100% !important;*/
}

@media(max-width: 768px) {
  .modal-dialog {
    min-height: calc(100vh - 20px);
  }
}

/*todo these area here if we want to resize the default modal sizes in bootstrap*/
.modal-sm {
  /*max-width: 100% !important;
  width: 900px !important;*/
}

.modal-md {
}

.modal-lg {
}

.twico-modal-header {
  padding-top: 12px;
  padding-bottom: 16px;
  font-family: nevosemibold;
  font-size: 28px;
}

.twico-modal-sub-header {
  font-family: nevo;
  font-size: 24px;
  color: #006580;
}

.twico-modal-close {
  padding-top: 26px;
  padding-right: 26px;
  cursor: pointer;
  float: right;
}

.invalid-modal-header {
  font-family: nevosemibold;
  font-size: 28px;
  text-align: left;
  color: #4B4F54;
}

.invalid-modal-sub-header {
  font-family: nevosemibold;
  font-size: 24px;
  text-align: left;
  color: #006580;
}

.img-info {
  width: 75% !important;
  height: 75% !important;
}

.top-pad-45 {
  padding-top: 45px;
}

.top-pad-10 {
  padding-top: 10px;
}

.top-pad-15 {
  padding-top: 15px;
}

.top-pad-20 {
  padding-top: 20px;
}

.top-pad-30 {
  padding-top: 30px !important;
}

.top-pad-50 {
  padding-top: 50px !important;
}

.top-pad-60 {
  padding-top: 60px;
}

.top-pad-70 {
  padding-top: 70px;
}

.top-pad-90 {
  padding-top: 90px;
}

.top-pad-33 {
  padding-top: 33px;
}

.top-padding-5 {
  padding-top: 5%;
}

.bot-padding-2 {
  padding-bottom: 2%;
}

.bot-padding-6 {
  padding-bottom: 6px;
}

.bot-padding-5 {
  padding-bottom: 5%;
}

.bot-pad-10 {
  padding-bottom: 10px;
}

.bot-pad-15 {
  padding-bottom: 15px;
}

.bot-pad-20 {
  padding-bottom: 20px;
}

.bot-pad-25 {
  padding-bottom: 25px;
}

.bot-pad-30 {
  padding-bottom: 30px;
}

.bot-pad-35 {
  padding-bottom: 35px;
}

.bot-pad-45 {
  padding-bottom: 45px;
}

.bot-pad-50 {
  padding-bottom: 50px;
}

.bot-pad-52 {
  padding-bottom: 52px;
}

.bot-pad-65 {
  padding-bottom: 65px;
}

.bot-pad-80 {
  padding-bottom: 80px;
}

.bot-pad-87 {
  padding-bottom: 87px;
}

.bot-pad-150 {
  padding-bottom: 150px;
}

.left-margin-0 {
  margin-left: 0;
}

.left-margin-1 {
  margin-left: 1%;
}

.left-margin-9 {
  margin-left: 9px;
}

.left-margin-10 {
  margin-left: 10%;
}

.left-mar-30 {
  margin-left: 30px;
}

.left-mar-40 {
  margin-left: 100px;
}

.left-margin-30px {
  margin-left: 30px;
}

.bot-mar-2 {
  margin-bottom: 2%;
}

.bot-mar-3 {
  margin-bottom: 3%;
}

.bot-mar-4 {
  margin-bottom: 4%;
}

.bot-mar-5 {
  margin-bottom: 5%;
}

.bot-mar-10 {
  margin-bottom: 10px;
}

.bot-mar-15 {
  margin-bottom: 15px;
}

.bot-mar-30 {
  margin-bottom: 30px;
}

.bot-mar-45 {
  margin-bottom: 45px;
}

.bot-mar-52 {
  margin-bottom: 52px;
}

.bot-mar-67 {
  margin-bottom: 67px;
}

.bot-mar-80 {
  margin-bottom: 80px;
}

.bot-mar-100 {
  margin-bottom: 100px;
}

.margin-right-33 {
  margin-right: -33px;
}

.margin-right-85 {
  margin-right: -85px;
}

.left-padding-2 {
  padding-left: 2%;
}

.left-padding-1 {
  padding-left: 1%;
}

.left-padding-5 {
  padding-left: 5%;
}

.left-padding-10 {
  padding-left: 10%;
}

.top-padding-1 {
  padding-top: 1%;
}

.left-padding-35 {
  padding-left: 35px;
}

.padding-left {
  padding-left: 5px;
}

.padding-bottom-17px {
  padding-bottom: 17px;
}

.right-pad-80 {
  padding-right: 80px;
}

.right-pad-40 {
  padding-right: 40px;
}

.right-pad-10 {
  padding-right: 10px;
}

.left-margin-25 {
  margin-left: 25%;
}

.test {
  margin-left: 10%;
}

.left-pad-20 {
  padding-left: 20px;
}

.bot-mar-1 {
  margin-bottom: 1%;
}

.bot-mar-7 {
  margin-bottom: 7%;
}

.top-mar-1 {
  margin-top: 1%;
}

.top-mar-2 {
  margin-top: 2%;
}

.top-mar-4 {
  margin-top: 4%;
}

.margin-top-14 {
  margin-top: -14px;
}

.example-spacer {
  flex: 1 1 auto;
}

.top-mar-11 {
  margin-top: 11px;
}

.top-mar-15 {
  margin-top: 15px;
}

.top-mar-20 {
  margin-top: 20px !important;
}

.top-mar-90 {
  margin-top: 90px;
}

.top-pad-17 {
  padding-top: 17px;
}

.top-padding-8 {
  padding-top: 8%
}

.left-margin-2 {
  margin-left: 2%;
}

.left-margin-3 {
  margin-left: 3%;
}

.left-margin-5 {
  margin-left: 5%;
}

.left-margin-7 {
  margin-left: 7%;
}

.right-margin-5 {
  margin-right: 5%;
}

.width-75 {
  width: 75% !important;
}

.width-85 {
  width: 85% !important;
}

.font-32 {
  font-size: 32px;
}
/*Kill bootstrap's margins and paddings, we are going to do our own'*/
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding: 0 !important;
}

.twico-row {
  width: 100%;
}

.row-justify-content {
  justify-content: space-between;
}

.row {
  /*width: 100%;*/
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left
}

.text-right {
  text-align: right;
}

.information-text {
  font-family: 'Futura PT';
  font-size: 32px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.choose-package-button {
  font-family: futura-pt;
  font-weight: bold;
  font-size: 15px;
  color: white;
  background-color: #006580;
  width: 120px;
  height: 30px;
  border: 0;
  display: inline-block;
  padding: 6px 12px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  margin-bottom: 0;
}

.choose-package-button-top {
  font-family: futura-pt;
  font-weight: bold;
  font-size: 15px;
  color: white;
  background-color: #006580;
  width: 120px;
  height: 30px;
  border: 0;
  margin-top: 15px;
  margin-bottom: 5px;
}

.choose-package-button:hover {
  background-color: #e87722;
}

.choose-package-modal-button {
  font-family: futura-pt;
  font-weight: bold;
  font-size: 15px;
  color: white;
  background-color: #006580;
  width: 120px;
  height: 30px;
  border: 0;
  margin-left: 10px;
}

  .choose-package-modal-button:hover {
    background-color: #e87722;
  }

.prime-color {
  color: #7cc24d;
}

.primeplus-color {
  color: #3dae2b;
}

.twico360-color {
  color: #00833e;
}

.prime-color-background {
  background-color: #7cc24d;
}

.primeplus-color-background {
  background-color: #3dae2b;
}

.twico360-color-background {
  background-color: #00833e;
}

.page-header {
  position: sticky;
  background: white;
  top: 180px;
  opacity: 1;
  z-index: 1;
}

.choose-package-disclaimer {
  text-align: left;
  font-family: futura-pt;
  font-size: 12pt;
  color: #4B4F54;
  padding-bottom: 5%;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.section-divider-line {
  height: 1px;
  background-color: #4B4F54;
  width: 100%;
}

.custom-tooltip .tooltip-inner {
  color: #006580;
  background-color: #eeeeee;
  font-size: 100%;
  text-align: left !important;
  opacity: 1;
  z-index: 1;
  min-width: 1000px;
}

.custom-tooltip .arrow::before {
  border-right-color: #eeeeee !important;
}

.named-insured-tooltip .tooltip-inner {
  color: #006580;
  background-color: #eeeeee;
  font-size: 100%;
  text-align: left !important;
  opacity: 1;
  z-index: 1;
  min-width: 1000px;
}

.named-insured-tooltip .arrow::before {
  border-right-color: #eeeeee !important;
}

.sub-section-header {
  font-size: 32px;
  font-weight: 400;
}

.button-twicoGreen {
  font-family: futura-pt;
  font-weight: 500;
  font-size: 30px;
  color: #40A26F;
  text-align: left;
  background-color: #ffffff;
  border-color: transparent;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding-right: 45px;
}

.button-transparent {
  font-family: futura-pt;
  font-weight: 300;
  font-size: 30px;
  text-align: left;
  background-color: #ffffff;
  border-color: transparent;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  padding-right: 45px;
  color: #4B4F54;
}

.a-underline {
  color: #196ad4;
  text-decoration: underline;
  cursor: pointer;
}

#referalEmail {
  width: 60%;
}

.slider {
  -webkit-appearance: none;
  /*width: 45%;*/
  width: 300px;
  height: 12px;
  outline: none;
  background: #C7C7C7;
  overflow: hidden;
  margin-top: 2%;
}

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    box-shadow: -100vw 0 0 calc(100vw - 6px) #004E59;
    cursor: pointer;
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    border-radius: 50%;
  }

.twico-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 300px;
  height: 30px;
  background: url(./assets/img/TwicoDownArrow.svg) 97% / 13px no-repeat #fff;
  box-shadow: none;
  outline: 0px;
  border: 1px solid #4B4F54;
  opacity: 1;
  font-family: futura-pt;
  font-size: 20px;
  font-weight: 300;
  color: #4B4F54;
}

.twico-select-base-coverage {
  background: url(./assets/img/TwicoDownArrow.svg) 97% / 13px no-repeat #fff;
  font-size: 22px;
}

.twico-select-additional-coverage {
  margin-left: 10%;
}

.label-vertical-bar {
  color: #006580 !important;
  width: 10px !important;
}

.onoffswitch {
  position: relative;
  width: 80px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-top: 5px;
}

.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
}

  .onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 40px;
    padding: 0;
    line-height: 30px;
    font-size: 14px;
    color: white;
    font-family: futura-pt;
    font-weight: bold;
    box-sizing: border-box;
  }

  .onoffswitch-inner:before {
    content: "YES";
    padding-left: 10px;
    padding-top: 6px;
    background-color: #004E59;
    color: #FFFFFF;
  }

  .onoffswitch-inner:after {
    content: "NO";
    padding-right: 10px;
    padding-top: 6px;
    background-color: #EEEEEE;
    color: #4B4F54;
    text-align: right;
  }

.onoffswitch-switch {
  display: block;
  width: 30px;
  height: 30px;
  margin: 5px;
  background: #FFFFFF;
  position: absolute;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

.label-twicoGreen {
  color: #40A26F;
  font-family: futura-pt;
  font-size: 30px;
  font-weight: 500;
}

.customize-input {
  width: 63%;
  margin-left: 1%;
  font-family: futura-pt;
  font-size: 20px;
}

.button-add {
  width: 80px;
}

.text-information {
  text-align: left;
  font-family: futura-pt;
  letter-spacing: 0px;
  color: #4B4F54;
  opacity: 1;
  font-size: 20px;
  padding-left: 60px;
}

.radio-information-toolbar {
  padding-top: 2%;
}

  .radio-information-toolbar label {
    display: inline-block;
    color: #4B4F54;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    font-family: futura-pt;
    font-size: 22px;
    text-align: left;
    width: 25%;
    border-color: transparent;
    padding-left: 10px;
  }

  .radio-information-toolbar input[type="radio"]:focus + label {
    color: #4B4F54;
  }

  .radio-information-toolbar input[type="radio"]:checked + label {
    color: #4B4F54;
  }


input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  border: 1px solid gray;
}

  input[type='radio']:before {
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type="radio"]:checked:before {
    background: #004E59;
  }

  input[type="radio"]:checked {
    border-color: #004E59;
  }

.nav-left-text {
  margin-top: 75px;
  font-size: 25px;
}

.nav-right-text {
  margin-top: 75px;
  font-size: 25px;
}

.nav-back-to-top {
  margin-top: 70px;
  font-size: 29px;
}

.nav-previous {
  margin-top: 70px;
  font-size: 29px;
}

.img-icon {
  height: 75px;
  width: 75px;
  margin-right: 15px;
}

.end-img-icon {
  height: 75px;
  width: 75px;
}

.img-smiley-option {
  height: 100px;
  width: 100px;
}

.img-email-print {
  height: 75px;
  width: 75px;
}

.custom-div {
  height: 75px;
}

.container {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

  .container input {
    position: absolute;
    opacity: 0;
  }

.custom-checkbox {
  /*position: absolute;*/
  left: 0;
  height: 30px;
  width: 30px;
  background: #FFFFFF;
  border: 0.5px solid #707070;
  opacity: 1;
}

.container input:checked ~ .custom-checkbox {
  background-color: #004E59;
}

  .container input:checked ~ .custom-checkbox:after {
    display: block;
  }

.container .custom-checkbox:after {
  left: 9px;
  top: 3px;
  bottom: 3px;
  width: 9px;
  height: 22px;
  border: 0.5px solid #FFFFFF;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.sub-label-info {
  margin-left: 30px;
}

.home-address {
  height: 40px;
}

.risk-address-header {
  text-align: left;
  font-family: nevosemibold;
  font-size: 28px;
  color: #004E59;
}

/*largest viewport version above*/

@media only screen and (max-width: 1449px), screen and (max-height: 849px) {
  .payment-line-desktop {
    border-top: 1px solid #4B4F54;
    margin-top: 10%;
  }

  .validation-margin-top {
    margin-top: 40px;
  }

  .twico-modal-text {
    font-size: 18px;
  }

  .twico-modal-header {
    font-size: 23px;
  }

  .twico-modal-sub-header {
    font-size: 20px;
  }

  .invalid-modal-header {
    font-size: 23px;
  }

  .invalid-modal-sub-header {
    font-size: 20px;
  }

  .checkbox-label-information {
    font-size: 14pt;
  }

  .twico-container {
    margin: 0 32px;
  }

  .text-information {
    font-size: 18px;
  }

  .named-insured-tooltip .tooltip-inner {
    min-width: 500px;
  }

  .custom-tooltip .tooltip-inner {
    min-width: 550px;
  }

  .validation-error {
    font-size: 18px;
  }

  .home-date {
    display: inline-block !important;
  }

  .address-entry {
    display: inline-block !important;
  }

  .button {
    font-size: 22px;
    width: 155px;
    height: 38px;
  }

  .long-button {
    font-size: 22px;
    width: 268px;
    height: 38px;
  }

  .choose-package-button {
    font-size: 13px;
    width: 100px;
    height: 25px;
    display: block;
    margin-left: 57px;
    margin-bottom: 5px;
  }

  .choose-package-button-top {
    font-size: 13px;
    width: 100px;
    height: 25px;
    padding: 0px;
  }

  .choose-package-modal-button {
    font-size: 13px;
    width: 100px;
    height: 25px;
    padding: 0px;
  }

  .title {
    font-size: 23px;
  }

  .header {
    font-size: 20px;
  }

  .sub-header {
    font-size: 20px;
  }

  .label {
    font-size: 20px;
  }

  .sub-label {
    font-size: 18px;
  }

  .warning-message {
    font-size: 18px;
  }

  .warning-message-underlined {
    font-size: 18px;
  }

  .top-image-container {
    height: 218px;
    margin-bottom: 25px;
  }

  .error-message {
    font-size: 18px;
  }

  .input {
    width: 275px;
  }

  .date-input {
    width: 215px;
  }

  .input-margin-top {
    margin-top: 7px;
  }

  .section-divider {
    height: 25px;
  }

  .img-icon {
    height: 50px;
    width: 50px;
    margin-right: 12px;
  }

  .end-img-icon {
    height: 55px;
    width: 55px;
  }

  .custom-div {
    height: 50px;
  }

  .radio-toolbar {
    font-size: 20px;
  }

  .img-smiley-option {
    height: 70px;
    width: 70px;
  }

  .img-email-print {
    height: 55px;
    width: 55px;
  }

  .custom-checkbox {
    height: 25px;
    width: 25px;
  }

  .container .custom-checkbox:after {
    width: 7px;
    height: 20px;
    left: 7px;
    top: 1px;
    bottom: 1px;
  }

  .sub-label-info {
    margin-left: 10px;
  }

  .twico-select {
    background: url(./assets/img/TwicoDownArrow.svg) 97% / 13px no-repeat #fff;
    font-size: 18px;
  }

  .page-header {
    top: 95px;
  }

  .choose-package-disclaimer {
    padding-bottom: 8%;
  }

  .radio-information-toolbar label {
    font-size: 20px;
  }

  .risk-address-header {
    font-size: 20px;
  }
  
  .printemailtext {
    display: block;
    font-size: 12px;
    text-align: left;
    margin-top: -3px;
  }
}

@media only screen and (max-width: 1100px) {
  .sub-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 896px), screen and (max-height:649px) {
  .payment-line-desktop {
    border-top: 1px solid #4B4F54;
    margin-top: 15%;
  }
  .validation-margin-top {
    margin-top: 40px;
  }
  .medium-large {
    display: none;
  }

  .twico-modal-text {
    font-size: 17px;
  }

  .twico-modal-header {
    font-size: 20px;
  }

  .twico-modal-sub-header {
    font-size: 18px;
  }

  .invalid-modal-header {
    font-size: 20px;
  }

  .invalid-modal-sub-header {
    font-size: 18px;
  }

  .checkbox-label-information {
    font-size: 12pt;
  }

  .text-information {
    font-size: 16px;
  }

  .named-insured-tooltip .tooltip-inner {
    min-width: 100px;
  }

  .custom-tooltip .tooltip-inner {
    min-width: 150px;
  }

  .validation-error {
    font-size: 15px;
  }

  .home-date {
    display: inline-block !important;
  }

  .address-entry {
    display: block !important;
  }

  .button {
    font-size: 20px;
    width: 150px;
    height: 36px;
  }

  .long-button {
    font-size: 20px;
    width: 220px;
    height: 36px;
  }

  .choose-package-button {
    font-size: 20px;
    width: 150px;
    height: 36px;
    padding: 0px;
    margin-left: 128px;
  }

  /*.choose-package-button-buy {
    font-size: 12px;
    width: 150px;
    height: 36px;
    padding: 0px;
    margin-left: 0px;
  }*/

  .choose-package-button-buy {
    font-family: futura-pt;
    font-weight: bold;
    font-size: 20px;
    color: white;
    background-color: #006580;
    width: 150px;
    height: 36px;
    border: 0;
    display: block;
    padding: 0px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-left: 0px;
  }

  .choose-package-button-customize {
    font-family: futura-pt;
    font-weight: bold;
    font-size: 20px;
    color: white;
    background-color: #006580;
    width: 150px;
    height: 36px;
    border: 0;
    display: block;
    padding: 0px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-left: 0px;
  }

  .choose-package-button-top {
    font-size: 12px;
    width: 90px;
    height: 25px;
    padding: 0px;
  }

  .choose-package-modal-button {
    font-size: 12px;
    width: 90px;
    height: 25px;
    padding: 0px;
  }

  .title {
    font-size: 18px;
  }

  .header {
    font-size: 16px;
  }

  .sub-header {
    font-size: 16px;
  }

  .label {
    font-size: 16px;
  }

  .sub-label {
    font-size: 16px;
  }

  .warning-message {
    font-size: 16px;
  }

  .warning-message-underlined {
    font-size: 16px;
  }

  .twico-container {
    margin: 0 15px;
  }

  .progress-bar-container {
    padding-bottom: 15px !important;
  }

  .top-image-container {
    height: 125px;
    margin-bottom: 60px;
    top: -75px;    
  }

  .error-message {
    font-size: 16px;
  }

  .input {
    width: 250px;
  }

  .date-input {
    width: 190px;
  }

  .input-margin-top {
    margin-top: 5px;
  }

  .section-divider {
    height: 20px;
  }

  .img-icon {
    height: 45px;
    width: 45px;
    margin-right: 10px;
  }

  .end-img-icon {
    height: 45px;
    width: 45px;
  }

  .img-smiley-option {
    height: 65px;
    width: 65px;
  }

  .img-email-print {
    height: 60px;
    width: 60px;
  }

  .custom-checkbox {
    height: 20px;
    width: 20px;
  }

  .radio-toolbar {
    font-size: 16px;
  }

  .container .custom-checkbox:after {
    width: 6px;
    height: 18px;
    left: 6px;
    top: 0px;
    bottom: 0px;
  }

  .sub-label-info {
    margin-left: 0;
  }

  .twico-select {
    width: 250px;
    background: url(./assets/img/TwicoDownArrow.svg) 97% / 13px no-repeat #fff;
    font-size: 16px;
  }

  .sub-title {
    font-size: 20px;
    padding-left: 7px;
  }

  .page-header {
    top: 50px;
  }

  .prime-selected {
    background-color: white;
    border-bottom: 3px #7cc24d solid;
    opacity: 1;
  }

  .small-package-selector-selected {
    height: 20px;
    color: #7cc24d;
    font-family: nevo;
    font-weight: bolder;
    font-size: 18px;
    width: 42%;
  }

  .price-label-selected {
    color: #7cc24d;
    font-family: futura-pt;
    font-weight: 700;
    font-size: 18px;
  }

  .label-width-choosepackage {
    width: 100%;
  }

  .primeplus-selected {
    background-color: white;
    border-bottom: 3px #3dae2b solid;
    opacity: 1;
  }

  .primeplus-package-selector {
    height: 20px;
    color: #3dae2b;
    font-family: nevo;
    font-weight: bolder;
    font-size: 18px;
    width: 42%;
  }

  .primeplus-price-label-selected {
    color: #3dae2b;
    font-family: futura-pt;
    font-weight: 700;
    font-size: 18px;
  }

  .twico360-selected {
    background-color: white;
    border-bottom: 3px #00833e solid;
    opacity: 1;
  }

  .twico360-package-selector {
    height: 20px;
    color: #00833e;
    font-family: nevo;
    font-weight: bolder;
    font-size: 18px;
    width: 42%;
  }

  .twico360-price-label-selected {
    color: #00833e;
    font-family: futura-pt;
    font-weight: 700;
    font-size: 18px;
  }

  .risk-address-header {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .home-address {
    height: 30px;
    margin-bottom: 4%;
  }
  .validation-margin-top {
    margin-top: 40px;
  }

  .choose-button-margin {
    margin-bottom: 2%;
  }

  .sub-title {
    font-size: 16px;
    padding-left: 7px;
  }

  .img-email-print {
    height: 45px;
    width: 45px;
  }
}

@media only screen and (max-width: 576px) {
  .customize-label {
    max-width: 100px;
  }

  .custom-div {
    height: 35px;
  }

  .sub-title {
    font-size: 14px;
    padding-left: 7px;
  }

  .choose-package-disclaimer {
    padding-bottom: 14%;
  }

  .long-button {
    width: 177px;
  }

  .checkbox-label {
    margin-left: 5px;
    width: 100%
  }

  .input-margin-left {
    margin-left: 5px;
    width: 100%
  }

  .radio-information-toolbar label {
    width: 38%;
    font-size: 16px;
  }

  .risk-address-header {
    display: none;
  }

  .text-information {
    padding-left: 0;
  }

}

@media only screen and (max-width: 450px), screen and (max-height: 450px) {
  .medium-large {
    display: none;
  }
}

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
